import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { Subscription } from 'rxjs';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { ConfigService } from '../../services/config.service';
import { LanguageService } from '../../services/language.service';
import { PopupService } from '../../services/popup.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: false
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @ViewChild('changePasswordPopup', { static: false }) changePasswordPopup: DxPopupComponent;
  @ViewChild('passwordForm', { static: false }) passwordForm: DxFormComponent;

  passwordFormData: any = {
    altPasswort: '',
    passwort: '',
    passwortConfirm: '',
  };

  msg: string = '';
  messageTimeout: number;
  inProcess: boolean = false;

  subscription_get_PopupAsObservable: Subscription;
  constructor(
    private languageService: LanguageService,
    private apiService: ApiService,
    private popupService: PopupService,
    public configService: ConfigService
  ) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickSavePassword = this.onClickSavePassword.bind(this);

    this.subscription_get_PopupAsObservable = this.popupService.get_changePasswordPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true) {
        this.changePasswordPopup.instance.show();
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  repaintPopup() {
    if (this.changePasswordPopup !== undefined) {
      this.changePasswordPopup.instance.repaint();
    }
  }

  onClickSavePassword() {
    this.msg = '';
    var validationResult = this.passwordForm.instance.validate();
    if (validationResult.isValid) {
      this.inProcess = true;
      this.apiService.post(RouteKeys.Intern.Buerger.update_password, this.passwordFormData).subscribe({
        next: (response) => {
          this.inProcess = false;
          document.getElementById('psw_msg').className = 'msg-text color-success';
          this.setMessageText(this.languageService.translate('changedPassword'));
          setTimeout(() => {
            this.onClickClose();
          }, 3000);
        },
        error: (err) => {
          this.inProcess = false;
          document.getElementById('psw_msg').className = 'msg-text color-error';
          if (err.status === 403) {
            this.setMessageText(err?.error?.message || err?.error?.Message || this.languageService.translate('incorrectPassword'));
          } else {
            this.setMessageText(err?.error?.message || err?.error?.Message || this.languageService.translate('laterError'));
          }
          throw err?.error?.message || err?.error?.Message || this.languageService.translate('serverError');
        },
      });
    }
  }

  setMessageText(msg: string) {
    this.msg = msg;
    if (this.messageTimeout > 0) {
      clearTimeout(this.messageTimeout);
    }
    setTimeout(() => {
      this.msg = '';
    }, 8000);
  }

  onClickClose() {
    this.passwordForm.instance.resetValues();
    this.changePasswordPopup.instance.hide();
  }

  onPasswordChanged() {
    if (this.passwordFormData.passwortConfirm) {
      this.passwordForm.instance.validate();
    }
  }

  passwordComparison = () => this.passwordFormData.passwort;
}
