import { Component, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/share/services/api.service';
import { LanguageService } from 'src/app/share/services/language.service';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ConfigService } from '../../services/config.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: false
})
export class ContactComponent {
  @ViewChild('contactForm', { static: false }) contactForm: DxFormComponent;
  dataSource: DataSource;
  loading: boolean = false;
  msg: string = '';
  timeStamp = Date.now();
  isFaqOn: number = 0;
  linkToDownload: string = '';
  success: boolean = false;

  selectedLanguageObservable: Subscription;

  contactFormData: any = {
    name: '',
    email: '',
    betreff: '',
    betreff1: '',
    nachricht: '',
  };

  confirm: boolean = false;

  scrollbarMode: any = 'always';

  constructor(private apiService: ApiService, private languageService: LanguageService, public configService: ConfigService, private router: Router) {
    this.selectedLanguageObservable = languageService.get_selectedLanguageObservable.subscribe((lang) => {
      this.dataSource = new DataSource({
        key: 'id',
        load: (loadOptions) => {
          loadOptions.take = 100;
          return this.apiService
            .post<any>(RouteKeys.Faq.get_question, loadOptions)
            .toPromise()
            .then((response) => {
              return {
                data: response.data.filter((x) => !x.intern && x.sprache === lang.toUpperCase()),
                totalCount: response.totalCount,
                summary: response.summary,
                groupCount: response.groupCount,
              };
            })
            .catch((err) => {
              throw err.error ? err.error.message : err.message;
            });
        },
      });
    });
    this.isFaqOn = configService.getGlobalSettingAsNumber('isFaqOn');
    this.linkToDownload = configService.getGlobalSetting('linkZumDownloadbereich');
  }

  onClickReset = () => {
    this.onReset();
    this.msg = '';
  };

  onClickSend = () => {
    this.timeStamp = Date.now() - this.timeStamp;
    this.contactFormData.confirm = btoa('' + this.timeStamp);

    const validationFromResult = this.contactForm.instance.validate();
    if (validationFromResult.isValid) {
      this.loading = true;
      this.apiService.post<boolean>(RouteKeys.Contact.send_message, this.contactFormData).subscribe({
        next: (response) => {
          this.loading = false;
          if (response === true) {
            this.success = true;
            this.msg = this.languageService.translate('sentMessage');
            this.onReset();
          } else {
            this.success = false;
            this.msg = this.languageService.translate('laterError');
          }
        },
        error: (err) => {
          this.loading = false;
          this.success = false;
          this.msg = this.languageService.translate('mailDomainError');
        },
      });
    }
  };

  onReset() {
    this.contactForm.instance.resetValues();
    this.timeStamp = Date.now();
  }
}
