import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { Subscription } from 'rxjs';
import { UrlService } from '../../services/url.service';
import { PopupService } from '../../services/popup.service';
import { ConfigService } from '../../services/config.service';
import { DxGalleryComponent } from 'devextreme-angular/ui/gallery';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LanguageService } from '../../services/language.service';

@Component({
    selector: 'app-einrichtung-details',
    templateUrl: './einrichtung-details.component.html',
    styleUrls: ['./einrichtung-details.component.scss'],
    standalone: false
})
export class EinrichtungDetailsComponent implements OnDestroy {
  @ViewChild('detailPopup', { static: false }) detailPopup: DxPopupComponent;
  @ViewChild('gallery', { static: false }) gallery: DxGalleryComponent;

  withoutBtns: boolean = false;
  showGallery: boolean = true;
  isShowMap: boolean = false;
  isPreview: boolean = false;
  isDistance: boolean = false;

  itemToShowDetails: any;
  wochentage: any = null;
  selectedIds: number[] = [];
  beschreibung: SafeHtml;
  pictures: any;

  subscription_get_externSelectedIdsQueryParams: Subscription;
  subscription_get_PopupAsObservable: Subscription;

  get isSelected(): boolean {
    if (this.itemToShowDetails === null || this.itemToShowDetails === undefined) {
      return false;
    }

    if (this.itemToShowDetails.distance === null || this.itemToShowDetails.distance === undefined) {
      this.isDistance = false;
    } else {
      this.isDistance = true;
    }

    const exist = this.selectedIds.find((x) => x === this.itemToShowDetails.id);
    return exist !== null && exist !== undefined;
  }

  constructor(
    private urlService: UrlService,
    private popupService: PopupService,
    private configService: ConfigService,
    public sanitizer: DomSanitizer,
    public languageServise: LanguageService
  ) {
    this.onAbbruchClick = this.onAbbruchClick.bind(this);
    this.onSelectClick = this.onSelectClick.bind(this);
    this.onShown = this.onShown.bind(this);
    this.onHidden = this.onHidden.bind(this);

    this.wochentage = this.configService.wochentage;

    this.subscription_get_PopupAsObservable = this.popupService.get_detailPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true) {
        this.itemToShowDetails = context.item;
        this.isPreview = context.preview;
        if (languageServise.getLanguage() === 'en') {
          this.beschreibung = sanitizer.bypassSecurityTrustHtml(this.itemToShowDetails.beschreibungEN);
        } else {
          this.beschreibung = sanitizer.bypassSecurityTrustHtml(this.itemToShowDetails.beschreibung);
        }

        if (this.itemToShowDetails.galerie.length > 0) {
          this.showGallery = true;
        } else {
          this.showGallery = false;
        }
        this.detailPopup.instance.show();
      }
    });

    this.subscription_get_externSelectedIdsQueryParams = this.urlService.get_externSelectedIdsQueryParams.subscribe((ids) => {
      this.selectedIds = ids;
    });
  }

  onShown(e) {
    this.pictures = this.itemToShowDetails.galerie;
    if (this.gallery) {
      this.gallery.visible = true;
    }

    this.isShowMap = true;
  }

  onHidden(e) {
    this.pictures = null;
    if (this.gallery) {
      this.gallery.instance.resetOption('selectedIndex');
      this.gallery.visible = false;
    }
    this.isShowMap = false;
  }

  ngOnDestroy(): void {
    if (this.subscription_get_externSelectedIdsQueryParams) {
      this.subscription_get_externSelectedIdsQueryParams.unsubscribe();
    }
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  onSelectClick() {
    if (!this.isSelected) {
      this.selectedIds.push(this.itemToShowDetails.id);
    } else {
      this.selectedIds.forEach((_item, _index) => {
        if (_item === this.itemToShowDetails.id) {
          this.selectedIds.splice(_index, 1);
        }
      });
    }

    this.urlService.refreshQueryParams({
      i: this.selectedIds.join(','),
    });
  }

  onAbbruchClick() {
    this.detailPopup.instance.hide();
  }
}
