{
    "en": {
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "CheckState": "Check state",
        "Close": "Close",
        "Clear": "Clear",
        "Done": "Done",
        "Loading": "Loading...",
        "Select": "Select...",
        "Search": "Search",
        "Back": "Back",
        "OK": "OK",
        "Today": "Today",
        "Yesterday": "Yesterday",

        "dxCollectionWidget-noDataText": "No data to display",

        "dxDropDownEditor-selectLabel": "Select",

        "validation-required": "Required",
        "validation-required-formatted": "{0} is required",
        "validation-numeric": "Value must be a number",
        "validation-numeric-formatted": "{0} must be a number",
        "validation-range": "Value is out of range",
        "validation-range-formatted": "{0} is out of range",
        "validation-stringLength": "The length of the value is not correct",
        "validation-stringLength-formatted": "The length of {0} is not correct",
        "validation-custom": "Value is invalid",
        "validation-custom-formatted": "{0} is invalid",
        "validation-async": "Value is invalid",
        "validation-async-formatted": "{0} is invalid",
        "validation-compare": "Values do not match",
        "validation-compare-formatted": "{0} does not match",
        "validation-pattern": "Value does not match pattern",
        "validation-pattern-formatted": "{0} does not match pattern",
        "validation-email": "Email is invalid",
        "validation-email-formatted": "{0} is invalid",
        "validation-mask": "Value is invalid",

        "dxLookup-searchPlaceholder": "Minimum character number: {0}",

        "dxList-pullingDownText": "Pull down to refresh...",
        "dxList-pulledDownText": "Release to refresh...",
        "dxList-refreshingText": "Refreshing...",
        "dxList-pageLoadingText": "Loading...",
        "dxList-nextButtonText": "More",
        "dxList-selectAll": "Select All",
        "dxList-listAriaLabel": "Items",
        "dxList-listAriaLabel-deletable": "Deletable items",
        "dxListEditDecorator-delete": "Delete",
        "dxListEditDecorator-more": "More",
        "dxList-selectAll-indeterminate": "Half-checked",
        "dxList-selectAll-checked": "Checked",
        "dxList-selectAll-notChecked": "Not checked",

        "dxScrollView-pullingDownText": "Pull down to refresh...",
        "dxScrollView-pulledDownText": "Release to refresh...",
        "dxScrollView-refreshingText": "Refreshing...",
        "dxScrollView-reachBottomText": "Loading...",

        "dxDateBox-simulatedDataPickerTitleTime": "Select time",
        "dxDateBox-simulatedDataPickerTitleDate": "Select date",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Select date and time",
        "dxDateBox-validation-datetime": "Value must be a date or time",

        "dxDateRangeBox-invalidStartDateMessage": "Start value must be a date",
        "dxDateRangeBox-invalidEndDateMessage": "End value must be a date",
        "dxDateRangeBox-startDateOutOfRangeMessage": "Start date is out of range",
        "dxDateRangeBox-endDateOutOfRangeMessage": "End date is out of range",
        "dxDateRangeBox-startDateLabel": "Start Date",
        "dxDateRangeBox-endDateLabel": "End Date",

        "dxFileUploader-selectFile": "Select a file",
        "dxFileUploader-dropFile": "or Drop a file here",
        "dxFileUploader-bytes": "bytes",
        "dxFileUploader-kb": "KB",
        "dxFileUploader-Mb": "MB",
        "dxFileUploader-Gb": "GB",
        "dxFileUploader-upload": "Upload",
        "dxFileUploader-uploaded": "Uploaded",
        "dxFileUploader-readyToUpload": "Ready to upload",
        "dxFileUploader-uploadAbortedMessage": "Upload cancelled",
        "dxFileUploader-uploadFailedMessage": "Upload failed",
        "dxFileUploader-invalidFileExtension": "File type is not allowed",
        "dxFileUploader-invalidMaxFileSize": "File is too large",
        "dxFileUploader-invalidMinFileSize": "File is too small",

        "dxRangeSlider-ariaFrom": "From",
        "dxRangeSlider-ariaTill": "Till",
        "dxSwitch-switchedOnText": "ON",
        "dxSwitch-switchedOffText": "OFF",

        "dxForm-optionalMark": "optional",
        "dxForm-requiredMessage": "{0} is required",

        "dxNumberBox-invalidValueMessage": "Value must be a number",
        "dxNumberBox-noDataText": "No data",

        "dxDataGrid-emptyHeaderWithColumnChooserText": "Use {0} to display columns",
        "dxDataGrid-emptyHeaderWithGroupPanelText": "Drag a column from the group panel here",
        "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Use {0} or drag a column from the group panel",
        "dxDataGrid-emptyHeaderColumnChooserText": "column chooser",
        "dxDataGrid-columnChooserTitle": "Column Chooser",
        "dxDataGrid-columnChooserEmptyText": "Drag a column here to hide it",
        "dxDataGrid-groupContinuesMessage": "Continues on the next page",
        "dxDataGrid-groupContinuedMessage": "Continued from the previous page",
        "dxDataGrid-groupHeaderText": "Group by This Column",
        "dxDataGrid-ungroupHeaderText": "Ungroup",
        "dxDataGrid-ungroupAllText": "Ungroup All",
        "dxDataGrid-editingEditRow": "Edit",
        "dxDataGrid-editingSaveRowChanges": "Save",
        "dxDataGrid-editingCancelRowChanges": "Cancel",
        "dxDataGrid-editingDeleteRow": "Delete",
        "dxDataGrid-editingUndeleteRow": "Undelete",
        "dxDataGrid-editingConfirmDeleteMessage": "Are you sure you want to delete this record?",
        "dxDataGrid-validationCancelChanges": "Cancel changes",
        "dxDataGrid-groupPanelEmptyText": "Drag a column header here to group by that column",
        "dxDataGrid-noDataText": "No data",
        "dxDataGrid-searchPanelPlaceholder": "Search...",
        "dxDataGrid-filterRowShowAllText": "(All)",
        "dxDataGrid-filterRowResetOperationText": "Reset",
        "dxDataGrid-filterRowOperationEquals": "Equals",
        "dxDataGrid-filterRowOperationNotEquals": "Does not equal",
        "dxDataGrid-filterRowOperationLess": "Less than",
        "dxDataGrid-filterRowOperationLessOrEquals": "Less than or equal to",
        "dxDataGrid-filterRowOperationGreater": "Greater than",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Greater than or equal to",
        "dxDataGrid-filterRowOperationStartsWith": "Starts with",
        "dxDataGrid-filterRowOperationContains": "Contains",
        "dxDataGrid-filterRowOperationNotContains": "Does not contain",
        "dxDataGrid-filterRowOperationEndsWith": "Ends with",
        "dxDataGrid-filterRowOperationBetween": "Between",
        "dxDataGrid-filterRowOperationBetweenStartText": "Start",
        "dxDataGrid-filterRowOperationBetweenEndText": "End",
        "dxDataGrid-ariaSearchBox": "Search box",
        "dxDataGrid-applyFilterText": "Apply filter",
        "dxDataGrid-trueText": "true",
        "dxDataGrid-falseText": "false",
        "dxDataGrid-sortingAscendingText": "Sort Ascending",
        "dxDataGrid-sortingDescendingText": "Sort Descending",
        "dxDataGrid-sortingClearText": "Clear Sorting",
        "dxDataGrid-ariaNotSortedColumn": "Not sorted column",
        "dxDataGrid-ariaSortedAscendingColumn": "Column sorted in ascending order",
        "dxDataGrid-ariaSortedDescendingColumn": "Column sorted in descending order",
        "dxDataGrid-ariaSortIndex": "Sort index {0}",
        "dxDataGrid-editingSaveAllChanges": "Save changes",
        "dxDataGrid-editingCancelAllChanges": "Discard changes",
        "dxDataGrid-editingAddRow": "Add a row",
        "dxDataGrid-summaryMin": "Min: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Min of {1} is {0}",
        "dxDataGrid-summaryMax": "Max: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Max of {1} is {0}",
        "dxDataGrid-summaryAvg": "Avg: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Avg of {1} is {0}",
        "dxDataGrid-summarySum": "Sum: {0}",
        "dxDataGrid-summarySumOtherColumn": "Sum of {1} is {0}",
        "dxDataGrid-summaryCount": "Count: {0}",
        "dxDataGrid-columnFixingFix": "Set Fixed Position",
        "dxDataGrid-columnFixingUnfix": "Unfix",
        "dxDataGrid-columnFixingLeftPosition": "Left",
        "dxDataGrid-columnFixingRightPosition": "Right",
        "dxDataGrid-columnFixingStickyPosition": "Sticky",
        "dxDataGrid-exportTo": "Export",
        "dxDataGrid-exportToExcel": "Export to Excel file",
        "dxDataGrid-exporting": "Exporting...",
        "dxDataGrid-excelFormat": "Excel file",
        "dxDataGrid-selectedRows": "Selected rows",
        "dxDataGrid-exportSelectedRows": "Export selected rows to {0}",
        "dxDataGrid-exportAll": "Export all data to {0}",
        "dxDataGrid-headerFilterLabel": "Filter options",
        "dxDataGrid-headerFilterIndicatorLabel": "Show filter options for column '{0}'",
        "dxDataGrid-headerFilterEmptyValue": "(Blanks)",
        "dxDataGrid-headerFilterOK": "OK",
        "dxDataGrid-headerFilterCancel": "Cancel",
        "dxDataGrid-ariaAdaptiveCollapse": "Hide additional data",
        "dxDataGrid-ariaAdaptiveExpand": "Display additional data",
        "dxDataGrid-ariaColumn": "Column",
        "dxDataGrid-ariaColumnHeader": "Column header",
        "dxDataGrid-ariaValue": "Value",
        "dxDataGrid-ariaError": "Error",
        "dxDataGrid-ariaRevertButton": "Press Escape to discard the changes",
        "dxDataGrid-ariaFilterCell": "Filter cell",
        "dxDataGrid-ariaCollapse": "Collapse",
        "dxDataGrid-ariaModifiedCell": "Modified",
        "dxDataGrid-ariaDeletedCell": "Deleted",
        "dxDataGrid-ariaEditableCell": "Editable",
        "dxDataGrid-ariaExpand": "Expand",
        "dxDataGrid-ariaCollapsedRow": "Collapsed row",
        "dxDataGrid-ariaExpandedRow": "Expanded row",
        "dxDataGrid-ariaDataGrid": "Data grid with {0} rows and {1} columns",
        "dxDataGrid-ariaSearchInGrid": "Search in the data grid",
        "dxDataGrid-ariaSelectAll": "Select all",
        "dxDataGrid-ariaSelectRow": "Select row",
        "dxDataGrid-ariaToolbar": "Data grid toolbar",
        "dxDataGrid-ariaEditForm": "Edit form",
        "dxDataGrid-filterBuilderPopupTitle": "Filter Builder",
        "dxDataGrid-filterPanelCreateFilter": "Create Filter",
        "dxDataGrid-filterPanelClearFilter": "Clear",
        "dxDataGrid-filterPanelFilterEnabledHint": "Enable the filter",
        "dxDataGrid-masterDetail": "Cell with details",

        "dxTreeList-ariaTreeList": "Tree list with {0} rows and {1} columns",
        "dxTreeList-ariaExpandableInstruction": "Press Ctrl + right arrow to expand the focused node and Ctrl + left arrow to collapse it",
        "dxTreeList-ariaSearchInGrid": "Search in the tree list",
        "dxTreeList-ariaToolbar": "Tree list toolbar",
        "dxTreeList-editingAddRowToNode": "Add",

        "dxPager-infoText": "Page {0} of {1} ({2} items)",
        "dxPager-pagesCountText": "of",
        "dxPager-pageSize": "Items per page: {0}",
        "dxPager-pageSizesAllText": "All",
        "dxPager-page": "Page {0}",
        "dxPager-prevPage": "Previous page",
        "dxPager-nextPage": "Next page",
        "dxPager-ariaLabel": "Page navigation",
        "dxPager-ariaPageSize": "Page size",
        "dxPager-ariaPageNumber": "Page number",

        "dxPagination-infoText": "Page {0} of {1} ({2} items)",
        "dxPagination-pagesCountText": "of",
        "dxPagination-pageSize": "Items per page: {0}",
        "dxPagination-pageSizesAllText": "All",
        "dxPagination-page": "Page {0}",
        "dxPagination-prevPage": "Previous page",
        "dxPagination-nextPage": "Next page",
        "dxPagination-ariaLabel": "Page navigation",
        "dxPagination-ariaPageSize": "Page size",
        "dxPagination-ariaPageNumber": "Page number",

        "dxPivotGrid-grandTotal": "Grand Total",
        "dxPivotGrid-total": "{0} Total",
        "dxPivotGrid-fieldChooserTitle": "Field Chooser",
        "dxPivotGrid-showFieldChooser": "Show Field Chooser",
        "dxPivotGrid-expandAll": "Expand All",
        "dxPivotGrid-collapseAll": "Collapse All",
        "dxPivotGrid-sortColumnBySummary": "Sort \"{0}\" by This Column",
        "dxPivotGrid-sortRowBySummary": "Sort \"{0}\" by This Row",
        "dxPivotGrid-removeAllSorting": "Remove All Sorting",
        "dxPivotGrid-dataNotAvailable": "N/A",
        "dxPivotGrid-rowFields": "Row Fields",
        "dxPivotGrid-columnFields": "Column Fields",
        "dxPivotGrid-dataFields": "Data Fields",
        "dxPivotGrid-filterFields": "Filter Fields",
        "dxPivotGrid-allFields": "All Fields",
        "dxPivotGrid-columnFieldArea": "Drop Column Fields Here",
        "dxPivotGrid-dataFieldArea": "Drop Data Fields Here",
        "dxPivotGrid-rowFieldArea": "Drop Row Fields Here",
        "dxPivotGrid-filterFieldArea": "Drop Filter Fields Here",

        "dxScheduler-ariaLabel": "Scheduler. {0} view",
        

        "dxScheduler-appointmentAriaLabel-group": "Group: {0}",
        "dxScheduler-appointmentAriaLabel-recurring": "Recurring appointment",

        "dxScheduler-appointmentListAriaLabel": "Appointment list",


        "dxScheduler-editorLabelTitle": "Subject",
        "dxScheduler-editorLabelStartDate": "Start Date",
        "dxScheduler-editorLabelEndDate": "End Date",
        "dxScheduler-editorLabelDescription": "Description",
        "dxScheduler-editorLabelRecurrence": "Repeat",

        "dxScheduler-navigationPrevious": "Previous page",
        "dxScheduler-navigationNext": "Next page",

        "dxScheduler-openAppointment": "Open appointment",

        "dxScheduler-recurrenceNever": "Never",
        "dxScheduler-recurrenceMinutely": "Every minute",
        "dxScheduler-recurrenceHourly": "Hourly",
        "dxScheduler-recurrenceDaily": "Daily",
        "dxScheduler-recurrenceWeekly": "Weekly",
        "dxScheduler-recurrenceMonthly": "Monthly",
        "dxScheduler-recurrenceYearly": "Yearly",

        "dxScheduler-recurrenceRepeatEvery": "Repeat Every",
        "dxScheduler-recurrenceRepeatOn": "Repeat On",
        "dxScheduler-recurrenceEnd": "End repeat",
        "dxScheduler-recurrenceAfter": "After",
        "dxScheduler-recurrenceOn": "On",

        "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
        "dxScheduler-recurrenceRepeatHourly": "hour(s)",
        "dxScheduler-recurrenceRepeatDaily": "day(s)",
        "dxScheduler-recurrenceRepeatWeekly": "week(s)",
        "dxScheduler-recurrenceRepeatMonthly": "month(s)",
        "dxScheduler-recurrenceRepeatYearly": "year(s)",

        "dxScheduler-switcherDay": "Day",
        "dxScheduler-switcherWeek": "Week",
        "dxScheduler-switcherWorkWeek": "Work Week",
        "dxScheduler-switcherMonth": "Month",

        "dxScheduler-switcherAgenda": "Agenda",

        "dxScheduler-switcherTimelineDay": "Timeline Day",
        "dxScheduler-switcherTimelineWeek": "Timeline Week",
        "dxScheduler-switcherTimelineWorkWeek": "Timeline Work Week",
        "dxScheduler-switcherTimelineMonth": "Timeline Month",

        "dxScheduler-recurrenceRepeatOnDate": "on date",
        "dxScheduler-recurrenceRepeatCount": "occurrence(s)",
        "dxScheduler-allDay": "All day",

        "dxScheduler-ariaEditForm": "Edit form",

        "dxScheduler-confirmRecurrenceEditTitle": "Edit Recurring Appointment",
        "dxScheduler-confirmRecurrenceDeleteTitle": "Delete Recurring Appointment",

        "dxScheduler-confirmRecurrenceEditMessage": "Do you want to edit only this appointment or the whole series?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Do you want to delete only this appointment or the whole series?",

        "dxScheduler-confirmRecurrenceEditSeries": "Edit series",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Delete series",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Edit appointment",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Delete appointment",

        "dxScheduler-noTimezoneTitle": "No timezone",
        "dxScheduler-moreAppointments": "{0} more",

        "dxCalendar-currentDay": "Today",
        "dxCalendar-currentMonth": "Current month",
        "dxCalendar-currentYear": "Current year",
        "dxCalendar-currentYearRange": "Current year range",
        "dxCalendar-todayButtonText": "Today",
        "dxCalendar-ariaWidgetName": "Calendar",
        "dxCalendar-previousMonthButtonLabel": "Previous month",
        "dxCalendar-previousYearButtonLabel": "Previous year",
        "dxCalendar-previousDecadeButtonLabel": "Previous decade",
        "dxCalendar-previousCenturyButtonLabel": "Previous century",
        "dxCalendar-nextMonthButtonLabel": "Next month",
        "dxCalendar-nextYearButtonLabel": "Next year",
        "dxCalendar-nextDecadeButtonLabel": "Next decade",
        "dxCalendar-nextCenturyButtonLabel": "Next century",
        "dxCalendar-captionMonthLabel": "Month selection",
        "dxCalendar-captionYearLabel": "Year selection",
        "dxCalendar-captionDecadeLabel": "Decade selection",
        "dxCalendar-captionCenturyLabel": "Century selection",
        "dxCalendar-selectedDate": "The selected date is {0}",
        "dxCalendar-selectedDates": "The selected dates",
        "dxCalendar-selectedDateRange": "The selected date range is from {0} to {1}",
        "dxCalendar-selectedMultipleDateRange": "from {0} to {1}",
        "dxCalendar-selectedDateRangeCount": "There are {0} selected date ranges",
        "dxCalendar-readOnlyLabel": "Read-only calendar",

        "dxAvatar-defaultImageAlt": "Avatar",

        "dxChat-elementAriaLabel": "Chat",
        "dxChat-textareaPlaceholder": "Type a message",
        "dxChat-sendButtonAriaLabel": "Send",
        "dxChat-defaultUserName": "Unknown User",
        "dxChat-messageListAriaLabel": "Message list",
        "dxChat-alertListAriaLabel": "Error list",
        "dxChat-emptyListMessage": "There are no messages in this chat",
        "dxChat-emptyListPrompt": "Write your first message",
        "dxChat-typingMessageSingleUser": "{0} is typing...",
        "dxChat-typingMessageTwoUsers": "{0} and {1} are typing...",
        "dxChat-typingMessageThreeUsers": "{0}, {1} and {2} are typing...",
        "dxChat-typingMessageMultipleUsers": "{0} and others are typing...",

        "dxColorView-ariaRed": "Red",
        "dxColorView-ariaGreen": "Green",
        "dxColorView-ariaBlue": "Blue",
        "dxColorView-ariaAlpha": "Transparency",
        "dxColorView-ariaHex": "Color code",

        "dxTagBox-selected": "{0} selected",
        "dxTagBox-allSelected": "All selected ({0})",
        "dxTagBox-moreSelected": "{0} more",
        "dxTagBox-tagRoleDescription": "Tag. Press the delete button to remove this tag",

        "vizExport-printingButtonText": "Print",
        "vizExport-titleMenuText": "Exporting/Printing",
        "vizExport-exportButtonText": "{0} file",

        "dxFilterBuilder-and": "And",
        "dxFilterBuilder-or": "Or",
        "dxFilterBuilder-notAnd": "Not And",
        "dxFilterBuilder-notOr": "Not Or",
        "dxFilterBuilder-addCondition": "Add Condition",
        "dxFilterBuilder-addGroup": "Add Group",
        "dxFilterBuilder-enterValueText": "<enter a value>",
        "dxFilterBuilder-filterOperationEquals": "Equals",
        "dxFilterBuilder-filterOperationNotEquals": "Does not equal",
        "dxFilterBuilder-filterOperationLess": "Is less than",
        "dxFilterBuilder-filterOperationLessOrEquals": "Is less than or equal to",
        "dxFilterBuilder-filterOperationGreater": "Is greater than",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Is greater than or equal to",
        "dxFilterBuilder-filterOperationStartsWith": "Starts with",
        "dxFilterBuilder-filterOperationContains": "Contains",
        "dxFilterBuilder-filterOperationNotContains": "Does not contain",
        "dxFilterBuilder-filterOperationEndsWith": "Ends with",
        "dxFilterBuilder-filterOperationIsBlank": "Is blank",
        "dxFilterBuilder-filterOperationIsNotBlank": "Is not blank",
        "dxFilterBuilder-filterOperationBetween": "Is between",
        "dxFilterBuilder-filterOperationAnyOf": "Is any of",
        "dxFilterBuilder-filterOperationNoneOf": "Is none of",
        "dxFilterBuilder-filterAriaRootElement": "Filter builder",
        "dxFilterBuilder-filterAriaGroupLevel": "Level {0}",
        "dxFilterBuilder-filterAriaGroupItem": "Group item",
        "dxFilterBuilder-filterAriaOperationButton": "Operation",
        "dxFilterBuilder-filterAriaAddButton": "Add",
        "dxFilterBuilder-filterAriaRemoveButton": "Remove {0}",
        "dxFilterBuilder-filterAriaItemField": "Item field",
        "dxFilterBuilder-filterAriaItemOperation": "Item operation",
        "dxFilterBuilder-filterAriaItemValue": "Item value",

        "dxHtmlEditor-dialogColorCaption": "Change Font Color",
        "dxHtmlEditor-dialogBackgroundCaption": "Change Background Color",
        "dxHtmlEditor-dialogLinkCaption": "Add Link",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Text",
        "dxHtmlEditor-dialogLinkTargetField": "Open link in new window",
        "dxHtmlEditor-dialogImageCaption": "Add Image",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Alternate text",
        "dxHtmlEditor-dialogImageWidthField": "Width (px)",
        "dxHtmlEditor-dialogImageHeightField": "Height (px)",
        "dxHtmlEditor-dialogInsertTableRowsField": "Rows",
        "dxHtmlEditor-dialogInsertTableColumnsField": "Columns",
        "dxHtmlEditor-dialogInsertTableCaption": "Insert Table",
        "dxHtmlEditor-dialogUpdateImageCaption": "Update Image",
        "dxHtmlEditor-dialogImageUpdateButton": "Update",
        "dxHtmlEditor-dialogImageAddButton": "Add",
        "dxHtmlEditor-dialogImageSpecifyUrl": "From the Web",
        "dxHtmlEditor-dialogImageSelectFile": "From This Device",
        "dxHtmlEditor-dialogImageKeepAspectRatio": "Keep Aspect Ratio",
        "dxHtmlEditor-dialogImageEncodeToBase64": "Encode to Base64",
        "dxHtmlEditor-heading": "Heading",
        "dxHtmlEditor-normalText": "Normal text",
        "dxHtmlEditor-background": "Background Color",
        "dxHtmlEditor-bold": "Bold",
        "dxHtmlEditor-color": "Font Color",
        "dxHtmlEditor-font": "Font",
        "dxHtmlEditor-italic": "Italic",
        "dxHtmlEditor-link": "Add Link",
        "dxHtmlEditor-image": "Add Image",
        "dxHtmlEditor-size": "Size",
        "dxHtmlEditor-strike": "Strikethrough",
        "dxHtmlEditor-subscript": "Subscript",
        "dxHtmlEditor-superscript": "Superscript",
        "dxHtmlEditor-underline": "Underline",
        "dxHtmlEditor-blockquote": "Blockquote",
        "dxHtmlEditor-header": "Header",
        "dxHtmlEditor-increaseIndent": "Increase Indent",
        "dxHtmlEditor-decreaseIndent": "Decrease Indent",
        "dxHtmlEditor-orderedList": "Ordered List",
        "dxHtmlEditor-bulletList": "Bullet List",
        "dxHtmlEditor-alignLeft": "Align Left",
        "dxHtmlEditor-alignCenter": "Align Center",
        "dxHtmlEditor-alignRight": "Align Right",
        "dxHtmlEditor-alignJustify": "Align Justify",
        "dxHtmlEditor-codeBlock": "Code Block",
        "dxHtmlEditor-variable": "Add Variable",
        "dxHtmlEditor-undo": "Undo",
        "dxHtmlEditor-redo": "Redo",
        "dxHtmlEditor-clear": "Clear Formatting",
        "dxHtmlEditor-insertTable": "Insert Table",
        "dxHtmlEditor-insertHeaderRow": "Insert Header Row",
        "dxHtmlEditor-insertRowAbove": "Insert Row Above",
        "dxHtmlEditor-insertRowBelow": "Insert Row Below",
        "dxHtmlEditor-insertColumnLeft": "Insert Column Left",
        "dxHtmlEditor-insertColumnRight": "Insert Column Right",
        "dxHtmlEditor-deleteColumn": "Delete Column",
        "dxHtmlEditor-deleteRow": "Delete Row",
        "dxHtmlEditor-deleteTable": "Delete Table",
        "dxHtmlEditor-cellProperties": "Cell Properties",
        "dxHtmlEditor-tableProperties": "Table Properties",
        "dxHtmlEditor-insert": "Insert",
        "dxHtmlEditor-delete": "Delete",
        "dxHtmlEditor-border": "Border",
        "dxHtmlEditor-style": "Style",
        "dxHtmlEditor-width": "Width",
        "dxHtmlEditor-height": "Height",
        "dxHtmlEditor-borderColor": "Color",
        "dxHtmlEditor-borderWidth": "Border Width",
        "dxHtmlEditor-tableBackground": "Background",
        "dxHtmlEditor-dimensions": "Dimensions",
        "dxHtmlEditor-alignment": "Alignment",
        "dxHtmlEditor-horizontal": "Horizontal",
        "dxHtmlEditor-vertical": "Vertical",
        "dxHtmlEditor-paddingVertical": "Vertical Padding",
        "dxHtmlEditor-paddingHorizontal": "Horizontal Padding",
        "dxHtmlEditor-pixels": "Pixels",
        "dxHtmlEditor-list": "List",
        "dxHtmlEditor-ordered": "Ordered",
        "dxHtmlEditor-bullet": "Bullet",
        "dxHtmlEditor-align": "Align",
        "dxHtmlEditor-center": "Center",
        "dxHtmlEditor-left": "Left",
        "dxHtmlEditor-right": "Right",
        "dxHtmlEditor-indent": "Indent",
        "dxHtmlEditor-justify": "Justify",
        "dxHtmlEditor-borderStyleNone": "none",
        "dxHtmlEditor-borderStyleHidden": "hidden",
        "dxHtmlEditor-borderStyleDotted": "dotted",
        "dxHtmlEditor-borderStyleDashed": "dashed",
        "dxHtmlEditor-borderStyleSolid": "solid",
        "dxHtmlEditor-borderStyleDouble": "double",
        "dxHtmlEditor-borderStyleGroove": "groove",
        "dxHtmlEditor-borderStyleRidge": "ridge",
        "dxHtmlEditor-borderStyleInset": "inset",
        "dxHtmlEditor-borderStyleOutset": "outset",

        "dxFileManager-newDirectoryName": "Untitled directory",
        "dxFileManager-rootDirectoryName": "Files",
        "dxFileManager-errorNoAccess": "Access Denied. Operation could not be completed.",
        "dxFileManager-errorDirectoryExistsFormat": "Directory '{0}' already exists.",
        "dxFileManager-errorFileExistsFormat": "File '{0}' already exists.",
        "dxFileManager-errorFileNotFoundFormat": "File '{0}' not found.",
        "dxFileManager-errorDirectoryNotFoundFormat": "Directory '{0}' not found.",
        "dxFileManager-errorWrongFileExtension": "File extension is not allowed.",
        "dxFileManager-errorMaxFileSizeExceeded": "File size exceeds the maximum allowed size.",
        "dxFileManager-errorInvalidSymbols": "This name contains invalid characters.",
        "dxFileManager-errorDefault": "Unspecified error.",
        "dxFileManager-errorDirectoryOpenFailed": "The directory cannot be opened",

        "dxFileManager-commandCreate": "New directory",
        "dxFileManager-commandRename": "Rename",
        "dxFileManager-commandMove": "Move to",
        "dxFileManager-commandCopy": "Copy to",
        "dxFileManager-commandDelete": "Delete",
        "dxFileManager-commandDownload": "Download",
        "dxFileManager-commandUpload": "Upload files",
        "dxFileManager-commandRefresh": "Refresh",
        "dxFileManager-commandThumbnails": "Thumbnails View",
        "dxFileManager-commandDetails": "Details View",
        "dxFileManager-commandClearSelection": "Clear selection",
        "dxFileManager-commandShowNavPane": "Toggle navigation pane",

        "dxFileManager-dialogDirectoryChooserMoveTitle": "Move to",
        "dxFileManager-dialogDirectoryChooserMoveButtonText": "Move",
        "dxFileManager-dialogDirectoryChooserCopyTitle": "Copy to",
        "dxFileManager-dialogDirectoryChooserCopyButtonText": "Copy",
        "dxFileManager-dialogRenameItemTitle": "Rename",
        "dxFileManager-dialogRenameItemButtonText": "Save",
        "dxFileManager-dialogCreateDirectoryTitle": "New directory",
        "dxFileManager-dialogCreateDirectoryButtonText": "Create",
        "dxFileManager-dialogDeleteItemTitle": "Delete",
        "dxFileManager-dialogDeleteItemButtonText": "Delete",
        "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Are you sure you want to delete {0}?",
        "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Are you sure you want to delete {0} items?",
        "dxFileManager-dialogButtonCancel": "Cancel",

        "dxFileManager-editingCreateSingleItemProcessingMessage": "Creating a directory inside {0}",
        "dxFileManager-editingCreateSingleItemSuccessMessage": "Created a directory inside {0}",
        "dxFileManager-editingCreateSingleItemErrorMessage": "Directory was not created",
        "dxFileManager-editingCreateCommonErrorMessage": "Directory was not created",

        "dxFileManager-editingRenameSingleItemProcessingMessage": "Renaming an item inside {0}",
        "dxFileManager-editingRenameSingleItemSuccessMessage": "Renamed an item inside {0}",
        "dxFileManager-editingRenameSingleItemErrorMessage": "Item was not renamed",
        "dxFileManager-editingRenameCommonErrorMessage": "Item was not renamed",

        "dxFileManager-editingDeleteSingleItemProcessingMessage": "Deleting an item from {0}",
        "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Deleting {0} items from {1}",
        "dxFileManager-editingDeleteSingleItemSuccessMessage": "Deleted an item from {0}",
        "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "Deleted {0} items from {1}",
        "dxFileManager-editingDeleteSingleItemErrorMessage": "Item was not deleted",
        "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} items were not deleted",
        "dxFileManager-editingDeleteCommonErrorMessage": "Some items were not deleted",

        "dxFileManager-editingMoveSingleItemProcessingMessage": "Moving an item to {0}",
        "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Moving {0} items to {1}",
        "dxFileManager-editingMoveSingleItemSuccessMessage": "Moved an item to {0}",
        "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Moved {0} items to {1}",
        "dxFileManager-editingMoveSingleItemErrorMessage": "Item was not moved",
        "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} items were not moved",
        "dxFileManager-editingMoveCommonErrorMessage": "Some items were not moved",

        "dxFileManager-editingCopySingleItemProcessingMessage": "Copying an item to {0}",
        "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Copying {0} items to {1}",
        "dxFileManager-editingCopySingleItemSuccessMessage": "Copied an item to {0}",
        "dxFileManager-editingCopyMultipleItemsSuccessMessage": "Copied {0} items to {1}",
        "dxFileManager-editingCopySingleItemErrorMessage": "Item was not copied",
        "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} items were not copied",
        "dxFileManager-editingCopyCommonErrorMessage": "Some items were not copied",

        "dxFileManager-editingUploadSingleItemProcessingMessage": "Uploading an item to {0}",
        "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Uploading {0} items to {1}",
        "dxFileManager-editingUploadSingleItemSuccessMessage": "Uploaded an item to {0}",
        "dxFileManager-editingUploadMultipleItemsSuccessMessage": "Uploaded {0} items to {1}",
        "dxFileManager-editingUploadSingleItemErrorMessage": "Item was not uploaded",
        "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} items were not uploaded",
        "dxFileManager-editingUploadCanceledMessage": "Canceled",

        "dxFileManager-editingDownloadSingleItemErrorMessage": "Item was not downloaded",
        "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} items were not downloaded",

        "dxFileManager-listDetailsColumnCaptionName": "Name",
        "dxFileManager-listDetailsColumnCaptionDateModified": "Date Modified",
        "dxFileManager-listDetailsColumnCaptionFileSize": "File Size",

        "dxFileManager-listThumbnailsTooltipTextSize": "Size",
        "dxFileManager-listThumbnailsTooltipTextDateModified": "Date Modified",

        "dxFileManager-notificationProgressPanelTitle": "Progress",
        "dxFileManager-notificationProgressPanelEmptyListText": "No operations",
        "dxFileManager-notificationProgressPanelOperationCanceled": "Canceled",

        "dxDiagram-categoryGeneral": "General",
        "dxDiagram-categoryFlowchart": "Flowchart",
        "dxDiagram-categoryOrgChart": "Org Chart",
        "dxDiagram-categoryContainers": "Containers",
        "dxDiagram-categoryCustom": "Custom",

        "dxDiagram-commandExportToSvg": "Export to SVG",
        "dxDiagram-commandExportToPng": "Export to PNG",
        "dxDiagram-commandExportToJpg": "Export to JPEG",
        "dxDiagram-commandUndo": "Undo",
        "dxDiagram-commandRedo": "Redo",
        "dxDiagram-commandFontName": "Font Name",
        "dxDiagram-commandFontSize": "Font Size",
        "dxDiagram-commandBold": "Bold",
        "dxDiagram-commandItalic": "Italic",
        "dxDiagram-commandUnderline": "Underline",
        "dxDiagram-commandTextColor": "Font Color",
        "dxDiagram-commandLineColor": "Line Color",
        "dxDiagram-commandLineWidth": "Line Width",
        "dxDiagram-commandLineStyle": "Line Style",
        "dxDiagram-commandLineStyleSolid": "Solid",
        "dxDiagram-commandLineStyleDotted": "Dotted",
        "dxDiagram-commandLineStyleDashed": "Dashed",
        "dxDiagram-commandFillColor": "Fill Color",
        "dxDiagram-commandAlignLeft": "Align Left",
        "dxDiagram-commandAlignCenter": "Align Center",
        "dxDiagram-commandAlignRight": "Align Right",
        "dxDiagram-commandConnectorLineType": "Connector Line Type",
        "dxDiagram-commandConnectorLineStraight": "Straight",
        "dxDiagram-commandConnectorLineOrthogonal": "Orthogonal",
        "dxDiagram-commandConnectorLineStart": "Connector Line Start",
        "dxDiagram-commandConnectorLineEnd": "Connector Line End",
        "dxDiagram-commandConnectorLineNone": "None",
        "dxDiagram-commandConnectorLineArrow": "Arrow",
        "dxDiagram-commandFullscreen": "Full Screen",
        "dxDiagram-commandUnits": "Units",
        "dxDiagram-commandPageSize": "Page Size",
        "dxDiagram-commandPageOrientation": "Page Orientation",
        "dxDiagram-commandPageOrientationLandscape": "Landscape",
        "dxDiagram-commandPageOrientationPortrait": "Portrait",
        "dxDiagram-commandPageColor": "Page Color",
        "dxDiagram-commandShowGrid": "Show Grid",
        "dxDiagram-commandSnapToGrid": "Snap to Grid",
        "dxDiagram-commandGridSize": "Grid Size",
        "dxDiagram-commandZoomLevel": "Zoom Level",
        "dxDiagram-commandAutoZoom": "Auto Zoom",
        "dxDiagram-commandFitToContent": "Fit to Content",
        "dxDiagram-commandFitToWidth": "Fit to Width",
        "dxDiagram-commandAutoZoomByContent": "Auto Zoom by Content",
        "dxDiagram-commandAutoZoomByWidth": "Auto Zoom by Width",
        "dxDiagram-commandSimpleView": "Simple View",
        "dxDiagram-commandCut": "Cut",
        "dxDiagram-commandCopy": "Copy",
        "dxDiagram-commandPaste": "Paste",
        "dxDiagram-commandSelectAll": "Select All",
        "dxDiagram-commandDelete": "Delete",
        "dxDiagram-commandBringToFront": "Bring to Front",
        "dxDiagram-commandSendToBack": "Send to Back",
        "dxDiagram-commandLock": "Lock",
        "dxDiagram-commandUnlock": "Unlock",
        "dxDiagram-commandInsertShapeImage": "Insert Image...",
        "dxDiagram-commandEditShapeImage": "Change Image...",
        "dxDiagram-commandDeleteShapeImage": "Delete Image",
        "dxDiagram-commandLayoutLeftToRight": "Left-to-right",
        "dxDiagram-commandLayoutRightToLeft": "Right-to-left",
        "dxDiagram-commandLayoutTopToBottom": "Top-to-bottom",
        "dxDiagram-commandLayoutBottomToTop": "Bottom-to-top",

        "dxDiagram-unitIn": "in",
        "dxDiagram-unitCm": "cm",
        "dxDiagram-unitPx": "px",

        "dxDiagram-dialogButtonOK": "OK",
        "dxDiagram-dialogButtonCancel": "Cancel",
        "dxDiagram-dialogInsertShapeImageTitle": "Insert Image",
        "dxDiagram-dialogEditShapeImageTitle": "Change Image",
        "dxDiagram-dialogEditShapeImageSelectButton": "Select image",
        "dxDiagram-dialogEditShapeImageLabelText": "or drop a file here",

        "dxDiagram-uiExport": "Export",
        "dxDiagram-uiProperties": "Properties",
        "dxDiagram-uiSettings": "Settings",
        "dxDiagram-uiShowToolbox": "Show Toolbox",
        "dxDiagram-uiSearch": "Search",
        "dxDiagram-uiStyle": "Style",
        "dxDiagram-uiLayout": "Layout",
        "dxDiagram-uiLayoutTree": "Tree",
        "dxDiagram-uiLayoutLayered": "Layered",
        "dxDiagram-uiDiagram": "Diagram",
        "dxDiagram-uiText": "Text",
        "dxDiagram-uiObject": "Object",
        "dxDiagram-uiConnector": "Connector",
        "dxDiagram-uiPage": "Page",

        "dxDiagram-shapeText": "Text",
        "dxDiagram-shapeRectangle": "Rectangle",
        "dxDiagram-shapeEllipse": "Ellipse",
        "dxDiagram-shapeCross": "Cross",
        "dxDiagram-shapeTriangle": "Triangle",
        "dxDiagram-shapeDiamond": "Diamond",
        "dxDiagram-shapeHeart": "Heart",
        "dxDiagram-shapePentagon": "Pentagon",
        "dxDiagram-shapeHexagon": "Hexagon",
        "dxDiagram-shapeOctagon": "Octagon",
        "dxDiagram-shapeStar": "Star",
        "dxDiagram-shapeArrowLeft": "Left Arrow",
        "dxDiagram-shapeArrowUp": "Up Arrow",
        "dxDiagram-shapeArrowRight": "Right Arrow",
        "dxDiagram-shapeArrowDown": "Down Arrow",
        "dxDiagram-shapeArrowUpDown": "Up Down Arrow",
        "dxDiagram-shapeArrowLeftRight": "Left Right Arrow",
        "dxDiagram-shapeProcess": "Process",
        "dxDiagram-shapeDecision": "Decision",
        "dxDiagram-shapeTerminator": "Terminator",
        "dxDiagram-shapePredefinedProcess": "Predefined Process",
        "dxDiagram-shapeDocument": "Document",
        "dxDiagram-shapeMultipleDocuments": "Multiple Documents",
        "dxDiagram-shapeManualInput": "Manual Input",
        "dxDiagram-shapePreparation": "Preparation",
        "dxDiagram-shapeData": "Data",
        "dxDiagram-shapeDatabase": "Database",
        "dxDiagram-shapeHardDisk": "Hard Disk",
        "dxDiagram-shapeInternalStorage": "Internal Storage",
        "dxDiagram-shapePaperTape": "Paper Tape",
        "dxDiagram-shapeManualOperation": "Manual Operation",
        "dxDiagram-shapeDelay": "Delay",
        "dxDiagram-shapeStoredData": "Stored Data",
        "dxDiagram-shapeDisplay": "Display",
        "dxDiagram-shapeMerge": "Merge",
        "dxDiagram-shapeConnector": "Connector",
        "dxDiagram-shapeOr": "Or",
        "dxDiagram-shapeSummingJunction": "Summing Junction",
        "dxDiagram-shapeContainerDefaultText": "Container",
        "dxDiagram-shapeVerticalContainer": "Vertical Container",
        "dxDiagram-shapeHorizontalContainer": "Horizontal Container",
        "dxDiagram-shapeCardDefaultText": "Person's Name",
        "dxDiagram-shapeCardWithImageOnLeft": "Card with Image on the Left",
        "dxDiagram-shapeCardWithImageOnTop": "Card with Image on the Top",
        "dxDiagram-shapeCardWithImageOnRight": "Card with Image on the Right",

        "dxGantt-dialogTitle": "Title",
        "dxGantt-dialogStartTitle": "Start",
        "dxGantt-dialogEndTitle": "End",
        "dxGantt-dialogProgressTitle": "Progress",
        "dxGantt-dialogResourcesTitle": "Resources",
        "dxGantt-dialogResourceManagerTitle": "Resource Manager",
        "dxGantt-dialogTaskDetailsTitle": "Task Details",
        "dxGantt-dialogEditResourceListHint": "Edit Resource List",
        "dxGantt-dialogEditNoResources": "No resources",
        "dxGantt-dialogButtonAdd": "Add",
        "dxGantt-contextMenuNewTask": "New Task",
        "dxGantt-contextMenuNewSubtask": "New Subtask",
        "dxGantt-contextMenuDeleteTask": "Delete Task",
        "dxGantt-contextMenuDeleteDependency": "Delete Dependency",
        "dxGantt-dialogTaskDeleteConfirmation": "Deleting a task also deletes all its dependencies and subtasks. Are you sure you want to delete this task?",
        "dxGantt-dialogDependencyDeleteConfirmation": "Are you sure you want to delete the dependency from the task?",
        "dxGantt-dialogResourcesDeleteConfirmation": "Deleting a resource also deletes it from tasks to which this resource is assigned. Are you sure you want to delete these resources? Resources: {0}",
        "dxGantt-dialogConstraintCriticalViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. This change would conflict with dependency rules. How would you like to proceed?",
        "dxGantt-dialogConstraintViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. How would you like to proceed?",
        "dxGantt-dialogCancelOperationMessage": "Cancel the operation",
        "dxGantt-dialogDeleteDependencyMessage": "Delete the dependency",
        "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Move the task and keep the dependency",
        "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. This change would conflict with dependency rules. How would you like to proceed?",
        "dxGantt-dialogConstraintViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. How would you like to proceed?",
        "dxGantt-dialogDeleteDependenciesMessage": "Delete the dependency relations",
        "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Move the task and keep the dependencies",
        "dxGantt-undo": "Undo",
        "dxGantt-redo": "Redo",
        "dxGantt-expandAll": "Expand All",
        "dxGantt-collapseAll": "Collapse All",
        "dxGantt-addNewTask": "Add New Task",
        "dxGantt-deleteSelectedTask": "Delete Selected Task",
        "dxGantt-zoomIn": "Zoom In",
        "dxGantt-zoomOut": "Zoom Out",
        "dxGantt-fullScreen": "Full Screen",
        "dxGantt-quarter": "Q{0}",
        "dxGantt-sortingAscendingText": "Sort Ascending",
        "dxGantt-sortingDescendingText": "Sort Descending",
        "dxGantt-sortingClearText": "Clear Sorting",
        "dxGantt-showResources": "Show Resources",
        "dxGantt-showDependencies": "Show Dependencies",
        "dxGantt-dialogStartDateValidation": "Start date must be after {0}",
        "dxGantt-dialogEndDateValidation": "End date must be after {0}",

        "dxGallery-itemName": "Gallery item",

        "dxMultiView-elementAriaRoleDescription": "MultiView",
        "dxMultiView-elementAriaLabel": "Use the arrow keys or swipe to navigate between views",
        "dxMultiView-itemAriaRoleDescription": "View",
        "dxMultiView-itemAriaLabel": "{0} of {1}",

        "dxSplitter-resizeHandleAriaLabel": "Split bar"
    }
}
