import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './share/guards/auth.guard';
import { StorageService } from './share/services/storage.service';
import { ApiService } from './share/services/api.service';
import { AuthService } from './share/services/auth.service';
import { authInterceptorProvider } from './share/interceptors/auth.interceptor';
import { InjectorModule } from './share/injection/injector.module';
import { LanguageService } from './share/services/language.service';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { RollenGuard } from './share/guards/rollen.guard';
import { UrlService } from './share/services/url.service';
import { PopupService } from './share/services/popup.service';
import { ConfigService } from './share/services/config.service';
import { errorInterceptorProvider } from './share/interceptors/error.interceptor';
import { ErrorHandlerPopupComponent } from './share/components/error-handler-popup/error-handler-popup.component';
import { GlobalErrorHandler } from './share/helper/error.handler';
import { SysService } from './share/services/sys.service';

registerLocaleData(localeDe);
registerLocaleData(localeEn);

@NgModule({ declarations: [AppComponent, ErrorHandlerPopupComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule, InjectorModule.forRoot()], providers: [
        { provide: LOCALE_ID, useValue: 'de' },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        authInterceptorProvider,
        errorInterceptorProvider,
        StorageService,
        ApiService,
        AuthService,
        ConfigService,
        AuthGuard,
        LanguageService,
        RollenGuard,
        UrlService,
        PopupService,
        SysService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
