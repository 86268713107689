import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { UrlService } from '../../services/url.service';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    standalone: false
})
export class CardComponent implements OnDestroy {

  @Input () public bodyText: string = '';
  @Input () public headerText: string = '';
  @Input () public buttonText: string = '';
  @Input () public buttonIcon: string = '';
  @Input () public buttonAlignmentRight: boolean = false;
  @Input () public canBeDisabled: boolean = false;

  @Output () onBtnClick = new EventEmitter<boolean>();
  
  selectedIds: number[] = [];
  isSelected: boolean = false;
  subscription_get_externSelectedIdsQueryParams: Subscription;

  constructor(private urlService: UrlService) { 
    this.subscription_get_externSelectedIdsQueryParams = this.urlService.get_externSelectedIdsQueryParams.subscribe(ids=>{
      this.selectedIds = ids;
      this.isSelected = this.selectedIds.length > 0;
    });
  }

  onClick() {
    this.onBtnClick.emit();
  }

  ngOnDestroy(): void {
    if (this.subscription_get_externSelectedIdsQueryParams) {
      this.subscription_get_externSelectedIdsQueryParams.unsubscribe();
    }
  }  
}
